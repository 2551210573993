<template>
  <form @submit.prevent="saveItem">
    <b-row>
      <b-col cols="12" md="6">
        <b-card title="Terms and Conditions">
          <b-row class="mt-2">
            <b-col
              cols="12"
              v-for="(item, index) in formPayload.other.term_and_conditions"
              :key="index"
            >
              <b-row>
                <b-col cols="11">
                  <div class="form-group">
                    <b-form-textarea
                      rows="1"
                      max-rows="6"
                      type="text"
                      class="form-control"
                      v-model="formPayload.other.term_and_conditions[index]"
                      maxlength="500"
                    />
                    <small
                      v-for="(validation, index) in validations[index]"
                      :key="`errorName${index}`"
                      class="text-danger"
                      >{{ validation }}</small
                    >
                  </div>
                </b-col>
                <b-col cols="1">
                  <b-button
                    variant="danger"
                    class="btn waves-effect waves-float waves-light btn-danger"
                    style="padding: 6px"
                    @click="removeItem(index)"
                  >
                    <trash-icon size="1x" class="custom-class"></trash-icon>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary w-100"
            @click="addForm()"
          >
            Add Terms and Conditions
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card title="Background">
          <b-row class="mt-2">
            <b-col cols="12" :key="index">
              <div class="form-group">
                <textarea
                  class="form-control"
                  v-model="formPayload.other.background"
                  style="height: 200px"
                />
                <small
                  v-for="(validation, index) in validations['background']"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-col cols="12" class="p-0 mt-2">
      <template v-if="isLoading">
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
          disabled
        >
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </button>
      </template>
      <template v-else>
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Save
        </button>
      </template>
    </b-col>
  </form>
</template>

<script>
import { TrashIcon } from "vue-feather-icons";
import {
  BCard,
  BCardText,
  BForm,
  BFormTextarea,
  BButton,
  BCol,
  BRow,
  BSpinner,
  BFormFile,
} from "bootstrap-vue";
import { successNotification, errorNotification } from "@/auth/utils";
export default {
  components: {
    BCard,
    BCardText,
    BForm,
    BFormTextarea,
    BButton,
    BCol,
    BRow,
    BSpinner,
    BFormFile,
    TrashIcon,
  },

  data() {
    return {
      formPayload: {
        type: "freebies-reward",
        other: {
          background: "",
          term_and_conditions: [],
        },
      },
      isLoading: false,
      validations: "",
    };
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  methods: {
    getData() {
      this.isLoading = true;
      this.$http.get("/api/v1/admin/page/freebies-reward").then((response) => {
        this.formPayload = response.data.data;
        this.isLoading = false;
      });
    },
    addForm() {
      this.formPayload.other.term_and_conditions.push("");
    },
    removeItem(index) {
      this.formPayload.other.term_and_conditions.splice(index, 1);
    },
    saveItem() {
      this.isLoading = true;
      const payload = new FormData();
      payload.append("type", this.formPayload.type);
      payload.append("other[background]", this.formPayload.other.background);
      this.formPayload.other.term_and_conditions.forEach((term, index) => {
        payload.append(`other[term_and_conditions][${index}]`, term);
      });
      this.$http
        .post(`/api/v1/admin/page/freebies-reward`, payload, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          successNotification(
            this,
            "Success",
            "Freebies Rewards Berhasil di update!"
          );
          this.isLoading = false;
          this.getData();
        })
        .catch((error) => {
          if (error.response.data.meta.messages) {
            errorNotification(this, "Error", error.response.data.meta.messages);
          } else if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
  },

  created() {
    this.getData();
  },
};
</script>

<style>
.product__icon {
  width: 24px;
  height: 24px;
}
</style>
